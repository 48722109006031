import 'utils/yup-messages';
import 'styles/tailwind.css';
import 'styles/global.css';
import React, { ComponentType, ReactNode } from 'react';
import SWR from '../components/context/swr';
import { Provider as SessionProvider } from 'next-auth/client';
import Head from 'next/head';

export default function PaperstackApp({
  Component,
  pageProps
}: {
  Component: any;
  pageProps: any;
}) {
  let layout = Component.layout ?? ((page: any) => page);
  return (
    <>
      <Head>
        <title>Paperstack | The best magazine on the planet</title>
        <link rel="stylesheet" href="https://use.typekit.net/ktx0ojf.css" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <SessionProvider session={pageProps.session}>
        <SWR>
          <Page page={Component} layout={layout} pageProps={pageProps} />
        </SWR>
      </SessionProvider>
    </>
  );
}

function Page(props: {
  page: ComponentType;
  layout(page: ReactNode, props: any): JSX.Element;
  pageProps: any;
}) {
  let PageComponent = props.page;
  // Left off:
  // Trying to convert LoggedInRoute and LoggedOutRoute to HOCs that wrap
  // layouts, either per page or the layout itself can apply it on export.
  // But there seems to be some kind of problem with applying the HOC. When
  // I apply it, I get bizarre looking errors about string refs and key props
  // inside the navbar layout itself when I try to use the page.
  //
  // I suspect I'm doing something wrong around the idea of components as the
  // definition of the view tree vs. the instances themselves
  let renderedLayout = props.layout(
    <PageComponent {...props.pageProps} />,
    props.pageProps
  );
  return <>{renderedLayout}</>;
}
